/* Form styles */
.form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
}
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    margin-bottom: 0;
}

/* Block (section) styles */
.section {
    margin: 10px 20px 0 20px;
}
.section-header {
    margin-bottom: 10px;
}

/* Table style changes */
thead {
    background-color: #e6f2ff;
}
tfoot,
td.total {
    font-weight: bold;
}
tbody {
    overflow-y: scroll;
}
.table > :not(caption) > * > * {
    padding: 0.1rem 0.5rem;
    font-size: 15px;
}
.warning,
.table-striped > tbody > tr:nth-of-type(odd) > .warning {
    color: darkred;
}

.table .description {
    width: calc(100% - 90px);
}
.table .value {
    width: 90px;
    text-align: center;
}

th.month,
td.month {
    width: 6%;
}
td.description,
td.tags {
    text-align: left;
}
td.date {
    width: 80px;
    text-align: center;
}
td.engagement,
td.time,
td.total,
td.type,
td.category,
td.company {
    width: 140px;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
td.tags {
    width: 18%;
}
td.ticket,
td.status,
td.priority {
    width: 65px;
}
td.tags,
td.summary {
    min-width: 200px;
    max-width: 0;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-striped > tbody > tr:nth-of-type(odd) > td.missing,
.table-striped > tbody > tr:nth-of-type(even) > td.missing {
    color: red;
}

th.highlight,
td.highlight,
.table-striped > tbody > tr:nth-of-type(odd) > td.highlight,
.table-striped > tbody > tr:nth-of-type(even) > td.highlight {
    background-color: rgba(155, 97, 255, 0.05)
}

tfoot td.description,
tfoot td.total {
    text-align: right;
    font-weight: bold;
}
tfoot td.value {
    font-weight: bold;
}

/* Table row and column changes */
.row, .block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px;
}
.column {
    width: 50%;
}
.column.bordered {
    width: calc(50% - 22px);
    margin: 10px 5px;
    padding: 5px;
    border: #f0f0f0 1px solid;
}

.dropdown {
    display: inline-block;
}

/* General page layout */
.page {
    display: flex;
    height: 100%;
    flex-direction: column;
}
.header {
    height: 86px;
    text-align: left;
    padding: 2px 30px 2px 30px;
    background-color: #f0f0f0;
    color: white;
    overflow: hidden;
    border-bottom: #e0e0e0 1px solid;
}
.subheader {
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: #e0e0e0 1px solid;
}
.body {
    flex: 1;
    position: relative;
    text-align: center;
    overflow: hidden;
}
.form-body {
    flex: 1;
    text-align: center;
    margin: 15px;
    overflow: hidden;
}
.form-field {
    width: 450px;
}
.scroll-y {
    position: relative;
    height: 100%;
    overflow-y: scroll !important;
}
.footer {
    height: 40px;
    text-align: left;
    background-color: white;
    padding: 8px 30px 0 30px;
    border-top: #e0e0e0 1px solid;
    font-size: 14px;
    color: #120d0d;
    overflow: hidden;
}

details {
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
}

/* Components */
#LoginForm {
    width: 35%;
}
#Logout {
    position: absolute;
    top: 40px;
    right: 0;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.password-container {
    position: relative
}
.password-eye {
    position: absolute;
    right: 16px;
    top: 10px;
    font-size: 20px;
    color: #777777;
}
.btn-error {
    height: 30px;
    padding: 2px;
}

/* Scroll-bar overrides */

/* width */
.scroll-y::-webkit-scrollbar {
    width: 7px;
}

/* Track */
.scroll-y::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
.scroll-y::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
}

/* Handle on hover */
.scroll-y::-webkit-scrollbar-thumb:hover {
    background: #666;
}
