/* GENERAL */
:root {
  --fnt-primary: #120d0d;
  --btn-primary: #9b61ff;
  --clr-warning: darkred;
}

.App {
  font-family: sans-serif;
  text-align: center;
  position: relative;
  height: 100vh;
}

.left-logo {
  height: 80px;
  width: auto;
}
.center-logo {
  height: 78px;
  width: 300px;
  object-fit: contain;
  position: absolute;
  left: 50%;
  top: 43px;
  transform: translate(-50%, -50%)
}

hr {
  margin: 0;
}
h3.name {
  color: darkblue;
}

.fnt-primary {
  color: var(--btn-primary);
}

.name {
  color: #707070;
  text-align: center;
}
.btn-wide {
  width: 250px;
  text-transform: uppercase;
}

summary {
  text-align: left;
  font-size: 20px;
  color: var(--btn-primary);
}

/* React-Bootstrap style changes */
.dropdown-menu {
  height: 380px;
  overflow-y: scroll;
}
