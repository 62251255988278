/* Loading spinner */
.spinner {
    position: absolute;
    left: calc(50% - 100px);
    top: calc(50% - 15px);
}

/* Components */
#SummaryStats {
    height: 350px;
}
#SummaryStats td {
    font-size: 16px;
}

.app-title {
    position: absolute;
    display: inline-block;
    margin-left: 30px;
    top: 32px;
    color: var(--fnt-primary);
}
.client-title {
    position: absolute;
    top: 44px;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.padding {
    padding-left: 8px;
    padding-right: 8px;
}

.navigate-main {
    position: absolute;
    top: 8px;
    right: 20px;
    display: inline-block;
}
.navigate-sub {
    position: absolute;
    top: 12px;
    right: 50px;
    display: inline-block;
}
.reload {
    position: absolute;
    top: 11px;
    right: 10px;
    display: inline-block;
}
.export {
    position: absolute;
    top: 0px;
    right: 20px;
    display: inline-block;
}

.selectable {
    cursor: pointer;
}

#utilization {
    background-color: #cce5ff;
}
#census {
    background-color: #e6f2ff;
}

#utilization td {
    font-size: 18px;
    font-weight: bold;
}

.toast {
    position: absolute;
    left: 50%;
    top: 40%;
    -ms-transform: translate(-50%, -40%);
    transform: translate(-50%, -40%);
}
.toast-header {
    background-color: #f8d7da;
}
.toast-body {
    background-color: white;
    border-radius: 5px;
}

/* Tooltips */
.tip-custom {
    padding: 0 10px;
}
.tip-title {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}
.tip-last-year {
    color: #31a5a4;
    margin: 0;
    padding: 0;
}
.tip-this-year {
    color: #3399ff;
    margin: 0;
    padding: 0;
}

/* React-Bootstrap button style changes */
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #9b61ff;
    --bs-btn-border-color: #9b61ff;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #ab8cff;
    --bs-btn-hover-border-color: #9b61ff;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #9b61ff;
    --bs-btn-active-border-color: #9b61ff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #9b61ff;
    --bs-btn-disabled-border-color: #9b61ff;
}

.btn-forgot {
    --bs-btn-color: #8B61FF;
    --bs-btn-bg: #FFF;
    --bs-btn-border-color: #FFF;
    --bs-btn-hover-color: #8B61FF;
    --bs-btn-hover-bg: #f9f7fc;
    --bs-btn-hover-border-color: #f0f0f0;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #f0f0f0;
    --bs-btn-active-border-color: #f0f0f0;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #f0f0f0;
    --bs-btn-disabled-border-color: #f0f0f0;
}
